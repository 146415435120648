import { create as fedopsFactory } from '@wix/fedops-logger';
import { DealerApi } from '../DealerApi';
import * as Raven from 'raven-js';
import { LoadAssetsOptions } from '../types';

export class DealerAssetsLoader {
  private readonly fedopsLogger: any;
  private readonly reporter: any;

  constructor() {
    this.fedopsLogger = fedopsFactory('dealer-client-api');
    this.reporter = new Raven.Client();
    this.reporter.config(
      'https://262ea4509c324735aef60928616a21c2@sentry.io/1245650',
    );
  }

  loadAsset(
    position: string,
    metasiteGuid?: string,
    options: LoadAssetsOptions = { ignoreCache: false },
    signedInstance?: string,
  ) {
    const fedopsLogger = this.getFedopsLogger();
    return DealerApi.loadAsset(
      { position, metasiteGuid, fedopsLogger, signedInstance },
      options,
    ).then(({ assetExist }) => ({
      assetExist,
    }));
  }

  loadOffers = <T>(location: string, signedInstance: string) =>
    DealerApi.loadOffers<T>(location, signedInstance, this.reporter);

  getFedopsLogger() {
    return this.fedopsLogger;
  }
}
