import { GetDealerDataResponse } from '../ServerApi';

const cacheRegistryKey = 'dealerAssetCache';
if (!global.hasOwnProperty(cacheRegistryKey)) {
  (global as any)[cacheRegistryKey] = new Map();
}

export const bannerDataMap: Map<
  string,
  Promise<GetDealerDataResponse>
> = (global as any)[cacheRegistryKey];

export const DealerAssetCache = {
  hasAsset: (bannerKey: string) => bannerDataMap.has(bannerKey),
  setAsset: (
    bannerKey: string,
    dealerResponse: Promise<GetDealerDataResponse>,
  ) => bannerDataMap.set(bannerKey, dealerResponse),
  getAsset: (bannerKey: string) => bannerDataMap.get(bannerKey),
  clearCache: () => bannerDataMap.clear(),
};
